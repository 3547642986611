import React from 'react'
import './Cards.css'
import Card from './Card'
const Cards = () => {
  return (
    <div className='container-fluid py-4 card-container'>
      <div className='container'>
        <div className="row py-3">
          <h1 className='main-heading'> Our Services</h1><hr className='main-hr'/>
        </div>
        <div className="row mb-4 text-center">
          <div className="col-lg-3 col-md-4 col-sm-6 d-flex justify-content-center">
              <Card 
              image="https://images.unsplash.com/photo-1726178543393-032f01abacd6?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              text="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form"
              heading="IT Consultancy"
              link="it-consultancy"
              />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 d-flex justify-content-center">
              <Card 
              image="https://images.unsplash.com/photo-1726808260756-ec1d4eceaf71?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHwzfHx8ZW58MHx8fHx8"
              text="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form"
              heading="Artificial Intelligence (AIaas)"
              link="artificial-intelligence"
              />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 d-flex justify-content-center">
              <Card 
              image="https://images.unsplash.com/photo-1726608070500-7d4df6bb6b2e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHw0Mnx8fGVufDB8fHx8fA%3D%3D"
              text="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form"
              heading="Web Solutions (SaaS)"
              />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 d-flex justify-content-center">
              <Card 
              image="https://plus.unsplash.com/premium_photo-1673002094399-adab5ba2b82c?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              text="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form"
              heading="Enterprises Resources Planning (SaaS)"
              />
          </div>
          
        </div>
        <div className="row  text-center">
          <div className="col-lg-3 col-md-4 col-sm-6 d-flex justify-content-center">
              <Card 
              image="https://plus.unsplash.com/premium_photo-1670055125884-63ef89e1011e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDEwfHx8ZW58MHx8fHx8"
              text="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form"
              heading="Cloud Infra (IaaS)"
              />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 d-flex justify-content-center">
              <Card 
              image="https://images.unsplash.com/photo-1726307771333-a5386f6638c9?q=80&w=1376&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              text="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form"
              heading="Engineering Serivces"
              />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 d-flex justify-content-center">
              <Card 
              image="https://images.unsplash.com/photo-1726242881301-b83dd29f3d56?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHwxNzh8fHxlbnwwfHx8fHw%3D"
              text="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form"
              heading="Tech Courses"
              />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 d-flex justify-content-center">
              <Card 
              image="https://images.unsplash.com/photo-1725714354811-f604286b4a5d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDExfHx8ZW58MHx8fHx8"
              text="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form"
              heading="Bussiness Process Outsourcing (BPO)"
              />
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default Cards
