import React from 'react'
import './AI_Card.css'

const AI_Card = ({persent,content}) => {
  return (
    <div className='card ai-card'>
      <div className="circle"></div>
     <h1>{persent}</h1>
     <p>{content}</p>
    </div>
  )
}

export default AI_Card
