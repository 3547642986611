import React from 'react'
import Slider from '../../components/Slider/CarouselComponent'
import Digital from '../../components/Digital Solutions/Digital_Solutions'
import Product from '../../components/Products and Platforms/Products_and_Platforms'
import Map from '../../components/Map/Map'
import Card from '../../components/Card/Cards'
const Home = () => {
  return (
    <div>
      <Slider/>
      <Digital/>
      <Card/>
      <Product/>
      <Map/>
    </div>
  )
}

export default Home
