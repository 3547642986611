import React from 'react'
import './Software_Developer.css'
import hero from '../../assets/images/software-developer.png'
const Software_Developer = () => {
  return (
    <>
        <div className="software-developer-hero">
            <img className='hero-img' src={hero} alt="" />
            <div className="hero-content-container">
                <div className="content">
                <span>TechTorch / Solution</span><hr/>
                <h1 className='hero-heading'>TechTorch Software Engineering Solutions</h1>
                </div>
            </div>
        </div>
        
        <div className="container py-lg-5 py-3">
            <div className="row">
                <div className="col-lg-7 col-md-8 col-sm-10 software-second-section">
                    <h1 className='mb-lg-5 mb-3'>Accelerate Product Development.</h1>
                    <h6 className='mb-lg-4 mb-3'>CHALLENGE</h6>
                    <p className='mb-lg-4 mb-3'>Today, software companies are focusing on launching their next-generation products by adopting device mesh, machine learning, analytics, big data, and cloud technologies. Companies are moving from products to services model by adopting the SaaS model. Navigating all of this requires competencies that are fresh and complex. Companies continue to manage the existing legacy portfolio of the products, which creates scale and bandwidth issues for launching new products.</p>
                    <h6 className='mb-lg-4 mb-3'>TechTorch SERVICES</h6>
                    <p>Our Software Product Engineering (SPE) service helps you to launch products faster. </p>
                    <p> TCS uses its domain expertise and COIN™ framework to support innovation-based product engineering. Here are our key services:</p>
                    <ul className='mb-lg-4 mb-3'>
                        <li><span>Complete product and platform development: </span> This ranges from product design and development to release.</li>
                        <li><span>Legacy product portfolio management: </span>Product sustenance services, including bug fixing, service pack management, and end-of-lifecycle of a product.</li>
                        <li><span>Next-generation product engineering: </span>Technology adoption and transformation services, including mobile enablement, SaaS product development, analytics etc. </li>
                        <li><span>Product integrations: </span>Develop and sustain product integrations, extensions, adapters, and plug-ins.</li>
                        <li><span>Flexible engagement models: </span> There is flexibility in roles, responsibilities, pricing, and engagement. These models allow engagement for a product or across product lines.</li>
                        
                    </ul>
                    <h6 className='mb-lg-4 mb-3'>BENEFITS</h6>
                    <ul>
                        <li>Innovate rapidly and cost-effectively by focusing on core activities </li>
                        <li>Gain bandwidth to focus on innovation and new product development</li>
                        <li>Faster launch of the product in the market</li>
                        <li>Shorten product development lifecycle</li>
                        <li>Reduce product development and sustenance cost </li>
                    </ul>
                </div>
            </div>
        </div>
    </>
  )
}

export default Software_Developer
