import React from 'react'
import './Resource_Staffing.css'
import hero from '../../assets/images/resource-staffing.png'
import staff1 from '../../assets/images/staffing.png'

const Resource_Staffing = () => {
  return (
    <div className='resource-staffing'>

      {/* Start Hero Section  */}
        <div className="hero">
            <img className='hero-img' src={hero} alt="" />
            <div className="hero-content-container">
                <div className="hero-content">
                <span>TechTorch / Staffing</span><hr/>
                <h1 className='hero-heading'>Empowering Your Business with Exceptional Talent</h1>
                </div>
            </div>
        </div>
      {/* End Hero Section  */}

      {/* Start Second Section  */}
      <div className="container py-lg-5 py-3 ">
        <div className="row">
          <div className="col-lg-6 col-md-6 mb-lg-0 mb-3">
              <img className='img-fluid img1' src={staff1} alt="" />
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="content">
            <span>SEE THE DIFFERENCE</span>
            <h2 className='my-3'>Do you need help for yourself <span>or a loved one?</span></h2>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Inventore quod quasi reprehenderit beatae adipisci iste vero sapiente ut, totam eum illo repudiandae cupiditate labore et voluptates deserunt impedit pariatur repellat fuga soluta similique. Laborum, nulla? Eaque totam minima laudantium placeat alias, corporis omnis esse saepe, optio pariatur temporibus dicta quod eum. Accusamus, illo. Aspernatur tenetur voluptatibus, adipisci iste quibusdam totam? A, consectetur praesentium perspiciatis aut omnis quis expedita eligendi nostrum? Deserunt sequi expedita adipisci consequatur nobis rerum? Modi, fugiat inventore est distinctio possimus eum voluptates molestias quasi nesciunt pariatur</p>
            </div>
            
          </div>
        </div>
      </div>
      {/* End Second Section  */}
    </div>
  )
}

export default Resource_Staffing
