import "./Products_and_Platforms.css"
import { Carousel } from 'react-bootstrap';

const Products_and_Platforms = () => {
    const slides = [
        {
            image: 'https://images.unsplash.com/photo-1604856420566-576ba98b53cd?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8ODl8fHNpbXBsZXxlbnwwfHwwfHx8MA%3D%3D',
            paragraph: "Paragraph1",
            content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",

        },

        {
            image: 'https://images.unsplash.com/photo-1493723843671-1d655e66ac1c?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8ODN8fHNpbXBsZXxlbnwwfHwwfHx8MA%3D%3D',
            paragraph: "Paragraph2",
            content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",

        },
        {
            image: 'https://images.unsplash.com/photo-1678599694191-0bd740f04065?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTA3fHxzaW1wbGV8ZW58MHx8MHx8fDA%3D',
            paragraph: "Paragraph3",
            content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
        },
        {
            image: 'https://images.unsplash.com/photo-1675586677866-6b91355b7e85?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzV8fHNpbXBsZSUyMGltYWdlfGVufDB8fDB8fHww',
            paragraph: "Paragraph4",
            content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
        }
    ]

    



    return (

        

        <>
        <div className=" container product-heading-container">
                 <h1 className="main-heading">Product and Platforms</h1><hr className="main-hr"/>
         </div>
            <Carousel controls={false} indicators={false}>
                {slides.map((slide, index) => (
                    <Carousel.Item key={index}>
                        <div className="container py-lg-5 py-3 product-slider">
                            <div className="row">
                                <div className="col-lg-6 gx-0">
                                    <div className="carousel-background" style={{ backgroundImage: `url(${slide.image})` }}>
                                    </div>
                                </div>
                                <div className="col-lg-6 gx-0">
                                    <div className='slider-content px-3'>
                                        <h3>{slide.paragraph}</h3>
                                        <p>{slide.content}</p>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
        </>

    )
}

export default Products_and_Platforms
