import React from 'react'
import './Industries.css';
import IndustriesTab from '../../components/IndustriesTab/IndustriesTab';



const Industries = () => {
  
  return (
    <div className='container industries-container py-lg-5 mt-5 py-md-4 py-sm-3'>
      <h1 className='mb-lg-3'>Industries</h1> 
      <IndustriesTab/> 
    </div>
  )
}

export default Industries