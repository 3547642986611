import React from 'react';
import './App.css'
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import NavigationBar from './components/Navbar/Navbar';
import Capabilities from './pages/Capabilities/Capabilities';
import Discover from './pages/Discover/Discover';
import Industries from './pages/Industries/Industries';
import Insights from './pages/Insights/Insights';
import Clients from './pages/Clients/Clients';
import Contact from './pages/Contacts/Contacts';
import ITConsultancy from './pages/IT Consultancy/ITConsultancy';
import Footer from './components/Footer/Footer';
import Artifical from './pages/Artificial Intelligence/Artifical_Intelligence';
import Home from './pages/HomePage/Home';
import CloudInfrastructure from './pages/Cloud Infrastructure Services/Cloud_Infrastructure';
import CyberSecurity from './pages/Cyber Security/Cyber_Security';
import SoftwareEngineering from './pages/Software Engineering Services/Software_Engineering_Services';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import SoftwareDeveloper from './pages/Software Developer/Software_Developer';
import ResourceStaffing from './pages/Resource Staffing/Resource_Staffing';
import ERP from './pages/ERP/ERP';
import OperationsManagement from './pages/Operations Management/Operations_Management';
import SCM from './pages/Supply Chain Management/SCM';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop/>
      <NavigationBar />
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/capabilities" element={<Capabilities />} />
        <Route path="/discover" element={<Discover />} />
        <Route path="/industries" element={<Industries />} />
        <Route path="/insights" element={<Insights />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/contact" element={<Contact />} />
        <Route path='/it-consultancy' element={<ITConsultancy/>}/>
        <Route path='/artificial-intelligence' element={<Artifical/>}/>
        <Route path='/cloud-infrastructure' element={<CloudInfrastructure/>}/>
        <Route path='/cyber-security' element={<CyberSecurity/>}/>
        <Route path="/software-engineering-services" element={<SoftwareEngineering/>}/>
        <Route path="/software-developer" element={<SoftwareDeveloper/>}/>
        <Route path="/resource-staffing" element={<ResourceStaffing/>}/>
        <Route path="/erp" element={<ERP/>}/>
        <Route path="/operations-management" element={<OperationsManagement/>}/>
        <Route path="/supply-chain" element={<SCM/>}/>
        
      </Routes>
      <Footer /> 
      </BrowserRouter>
  );
}

export default App;


