import React from 'react'
import './Card.css'

const Card = ({percentage,heading,content}) => {
  return (
    <div className='card cloud-card'>
        <h1 className='percentage'>{percentage}</h1>
        <div>
            <h4>{heading}</h4>
            <p>{content}</p>
        </div>
       
    </div>
  )
}

export default Card
