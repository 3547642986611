import React from 'react'
import './Artifical_Intelligence.css' 
import Card from '../../components/Card/Card'
import ai1 from '../../assets/images/AI/AI1.png'
import ai2 from '../../assets/images/AI/AI2.png'
import ai3 from '../../assets/images/AI/AI3.png'
import ai4 from '../../assets/images/AI/AI4.png';
import ai5 from '../../assets/images/AI/AI5.png'
import AICard from './AI Card/AI_Card'
const Artifical_Intelligence = () => {
  return (
    <div className='ai-main-container'>

      {/* start hero section  */}
      <div className='ai-top-image-container'>
        <h1>Shape the future with<br />
          human-first AI</h1>
          <div className="square square-1"></div>
          <div className="square square-2"></div>
          <div className="square square-3"></div>
          <div className="square square-4"></div>
          <div className="square square-5"></div>
          <div className="square square-6"></div>
          <div className="square square-7"></div>
          <div className="square square-8"></div>
          <div className="square square-9"></div>
          <div className="square square-10"></div>
          <div className="square square-11"></div>
          <div className="square square-12"></div>
      </div>

      {/* end hero section  */}

      {/* start card section  */}
      <div className='ai-container' >
        <div className="container">
          <div className="row">
            <h1 className='mb-lg-5'>AI in business: From potential to performance</h1>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6 xs-card-center">
              <AICard
              persent="55%"
              content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard "
              />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 xs-card-center">
            <AICard
              persent="85%"
              content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard "
              />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 xs-card-center">
            <AICard
              persent="65%"
              content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard "
              />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 xs-card-center">
            <AICard
              persent="90%"
              content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard "
              />
            </div>
          </div>
        </div>
      </div>

      {/* end card section  */}


      <div className='ai-container' >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-4">
              <img className='img-fluid ai-second-img' src={ai5} alt="" />
            </div>
            <div className="col-lg-8 col-md-8 py-5">
              <h2>There are many variations of passages of Lorem Ipsum available</h2>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            </div>
          </div>
        </div>
      </div>

      <div className='ai-container'>
        <div className="container">
          <div className="row">
            <h1 className='my-5'>What’s trending with AI</h1>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6 xs-card-center">
              <Card
                image={ai1}
                heading="This is Heading"
                text="Lorem Ipsum is simply dummy text of the printing and typesetting industry"
              />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 xs-card-center">
              <Card
                image={ai2}
                heading="This is Heading"
                text="Lorem Ipsum is simply dummy text of the printing and typesetting industry"
              />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 xs-card-center">
              <Card
                image={ai3}
                heading="This is Heading"
                text="Lorem Ipsum is simply dummy text of the printing and typesetting industry"
              />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 xs-card-center">
              <Card
                image={ai4}
                heading="This is Heading"
                text="Lorem Ipsum is simply dummy text of the printing and typesetting industry"
              />
            </div>
          </div>
        </div>
      </div>
      
      <div className='ai-container mb-0'>
          <div className="container">
            <div className="row ">
              <h1 className='mb-lg-5'>Partners in change</h1>
              <div className="col-lg-3 col-sm-4 col-6">
                <img className='img-fluid ai-partners-img' src="https://img.icons8.com/androidL/200/FFFFFF/amazon-web-services.png" alt="" />
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
              <img className='img-fluid ai-partners-img google-img' src="https://white.logodownload.org/wp-content/uploads/2020/11/google-white-logo.png" alt="" />
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
              <img className='img-fluid ai-partners-img microsoft-img' src="https://logodix.com/logo/1069296.png" alt="" />
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
              <img className='img-fluid ai-partners-img ibm-img' src="https://pngimg.com/d/ibm_PNG19649.png" alt="" />
              </div>
              
              <div className="col-lg-3 col-sm-4 col-6">
              <img className='img-fluid ai-partners-img' src="https://docs.nvidia.com/deeplearning/cudnn/latest/_static/nvidia-logo-white.png" alt="" />
              </div>
            </div>
          </div>
      </div>

    </div>
  )
}

export default Artifical_Intelligence
