import React, { useState} from 'react'
import './Digital_Solutions.css'
import TabCard from '../IndustriesTab/TabCard/TabCard';


const Digital_Solutions = () => {
    const [activeTab, setActiveTab] = useState('ERP');
       

    return (
        <div className='container py-5 nav-container'>
            <h1 className='main-heading'>Digital Solutions</h1><hr className='main-hr'/>
            <ul
            className="nav" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link tab-link ${activeTab === 'ERP' ? 'active' : ''}`}
                        id="ERP-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="ERP"
                        aria-selected={activeTab === 'ERP'}
                        onClick={() => setActiveTab('ERP')}>ERP</button>

                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link tab-link  ${activeTab === 'Operations Management' ? 'active' : ''}`}
                        id="Operations-Management-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="Operations Management"
                        aria-selected={activeTab === 'Operations Management'}
                        onClick={() => setActiveTab('Operations Management')}
                    >  Operations Management
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link tab-link  ${activeTab === 'Supply Chain Management' ? 'active' : ''}`}
                        id="Supply-Chain-Management-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="Supply Chain Management"
                        aria-selected={activeTab === 'Supply Chain Management'}
                        onClick={() => setActiveTab('Supply Chain Management')}
                    >  Supply Chain Management
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link tab-link  ${activeTab === 'Aviation Management' ? 'active' : ''}`}
                        id="Aviation-Management-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="Aviation Management"
                        aria-selected={activeTab === 'Aviation Management'}
                        onClick={() => setActiveTab('Aviation Management')}
                    >  Aviation Management
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link tab-link  ${activeTab === 'People Resources' ? 'active' : ''}`}
                        id="People-Resources-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="People Resources"
                        aria-selected={activeTab === 'People Resources'}
                        onClick={() => setActiveTab('People Resources')}
                    >  People Resources
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link tab-link  ${activeTab === 'Web Portals' ? 'active' : ''}`}
                        id="Web-Portals-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="Web Portals"
                        aria-selected={activeTab === 'Web Portals'}
                        onClick={() => setActiveTab('Web Portals')}
                    >  Web Portals
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link tab-link  ${activeTab === 'Financial Management' ? 'active' : ''}`}
                        id="Financial-Management-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="Financial Management"
                        aria-selected={activeTab === 'Financial Management'}
                        onClick={() => setActiveTab('Financial Management')}
                    >  Financial Management
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link tab-link  ${activeTab === 'Payment Management' ? 'active' : ''}`}
                        id="Payment-Management-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="Payment Management"
                        aria-selected={activeTab === 'Payment Management'}
                        onClick={() => setActiveTab('Payment Management')}
                    >  Payment Management
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link tab-link  ${activeTab === 'HealthCare & Hospital Management' ? 'active' : ''}`}
                        id="HealthCare-Hospital-Management-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="HealthCare & Hospital Management"
                        aria-selected={activeTab === 'HealthCare & Hospital Management'}
                        onClick={() => setActiveTab('HealthCare & Hospital Management')}
                    >  HealthCare & Hospital Management
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link tab-link  ${activeTab === 'CRM' ? 'active' : ''}`}
                        id="CRM-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="CRM"
                        aria-selected={activeTab === 'CRM'}
                        onClick={() => setActiveTab('CRM')}
                    >  CRM
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link tab-link  ${activeTab === 'Ecommerce' ? 'active' : ''}`}
                        id="Ecommerce-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="Ecommerce"
                        aria-selected={activeTab === 'Ecommerce'}
                        onClick={() => setActiveTab('Ecommerce')}
                    >  Ecommerce
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link tab-link  ${activeTab === 'Project Management' ? 'active' : ''}`}
                        id="Project-Management-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="Project Management"
                        aria-selected={activeTab === 'Project Management'}
                        onClick={() => setActiveTab('Project Management')}
                    >  Project Management
                    </button>
                </li>

            </ul>

            <div className="tab-content pt-3" id="myTabContent">
                <div
                    className={`tab-pane fade ${activeTab === 'ERP' ? 'show active' : ''}`}
                    id="ERP"
                    role="tabpanel"
                    aria-labelledby="EERP-tab"
                >
                    <div className='d-flex'>
                        <TabCard
                            heading="ERP"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />
                        <TabCard
                            heading="ERP"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />
                        <TabCard
                            heading="ERP"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />

                    </div>
                </div>

                <div
                    className={`tab-pane fade ${activeTab === 'Operations Management' ? 'show active' : ''}`}
                    id="Operations Management"
                    role="tabpanel"
                    aria-labelledby="Operations-Management-tab" >
                    <div className='d-flex'>
                        <TabCard
                            heading="Operations Management"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />
                        <TabCard
                            heading="Operations Management"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />


                    </div>
                </div>

                <div
                    className={`tab-pane fade ${activeTab === 'Supply Chain Management' ? 'show active' : ''}`}
                    id="Supply Chain Management"
                    role="tabpanel"
                    aria-labelledby="Supply-Chain-Management-tab" >
                    <div className='d-flex'>
                        <TabCard
                            heading="Supply Chain Management"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />
                        <TabCard
                            heading="Supply Chain Management"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />
                        <TabCard
                            heading="Supply Chain Management"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />
                    </div>
                </div>

                <div
                    className={`tab-pane fade ${activeTab === 'Aviation Management' ? 'show active' : ''}`}
                    id="Aviation Management"
                    role="tabpanel"
                    aria-labelledby="Aviation-Management-tab" >
                    <div className="d-flex">
                        <TabCard
                            heading="Aviation Management"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />
                        <TabCard
                            heading="Aviation Management"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />
                    </div>
                </div>

                <div
                    className={`tab-pane fade ${activeTab === 'People Resources' ? 'show active' : ''}`}
                    id="People Resources"
                    role="tabpanel"
                    aria-labelledby="People Resources-tab" >
                    <div className="d-flex">
                        <TabCard
                            heading="People Resources"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />
                        <TabCard
                            heading="People Resources"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />
                    </div>
                </div>

                <div
                    className={`tab-pane fade ${activeTab === 'Web Portals' ? 'show active' : ''}`}
                    id="Web Portals"
                    role="tabpanel"
                    aria-labelledby="Web-Portals-tab" >
                    <div className="d-flex">
                        <TabCard
                            heading="Web Portals"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />
                        <TabCard
                            heading="Web Portals"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />
                    </div>
                </div>

                <div
                    className={`tab-pane fade ${activeTab === 'Financial Management' ? 'show active' : ''}`}
                    id="Financial Management"
                    role="tabpanel"
                    aria-labelledby="Financial-Management-tab" >
                    <div className="d-flex">
                        <TabCard
                            heading="Financial Management"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />
                        <TabCard
                            heading="Financial Management"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />
                    </div>
                </div>

                <div
                    className={`tab-pane fade ${activeTab === 'Payment Management' ? 'show active' : ''}`}
                    id="Payment Management"
                    role="tabpanel"
                    aria-labelledby="Payment-Management-tab" >
                    <div className="d-flex">
                        <TabCard
                            heading="Payment Management"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />
                        <TabCard
                            heading="Payment Management"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />
                    </div>
                </div>

                <div
                    className={`tab-pane fade ${activeTab === 'HealthCare & Hospital Management' ? 'show active' : ''}`}
                    id="HealthCare & Hospital Management"
                    role="tabpanel"
                    aria-labelledby="HealthCare-Hospital-Management-tab" >
                    <div className="d-flex">
                        <TabCard
                            heading="HealthCare & Hospital Management"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />
                        <TabCard
                            heading="HealthCare & Hospital Management"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />
                    </div>
                </div>

                <div
                    className={`tab-pane fade ${activeTab === 'CRM' ? 'show active' : ''}`}
                    id="CRM"
                    role="tabpanel"
                    aria-labelledby="CRM-tab" >
                    <div className="d-flex">
                        <TabCard
                            heading="CRM"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />
                        <TabCard
                            heading="CRM"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />
                    </div>
                </div>

                <div
                    className={`tab-pane fade ${activeTab === 'Ecommerce' ? 'show active' : ''}`}
                    id="Ecommerce"
                    role="tabpanel"
                    aria-labelledby="Ecommerce-tab" >
                    <div className="d-flex">
                        <TabCard
                            heading="Ecommerce"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />
                        <TabCard
                            heading="Ecommerce"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />
                    </div>
                </div>

                <div
                    className={`tab-pane fade ${activeTab === 'Project Management' ? 'show active' : ''}`}
                    id="Project Management"
                    role="tabpanel"
                    aria-labelledby="Project-Management-tab" >
                    <div className="d-flex">
                        <TabCard
                            heading="Project Management"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />
                        <TabCard
                            heading="Project Management"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />
                    </div>
                </div>



            </div>
        </div>
    )
}

export default Digital_Solutions
