import './ITConsultancy.css';
import it from '../../assets/images/IT Consultancy1.png'
import hero from '../../assets/images/IT Consultancy.png'
const ITConsultancy = () => {
 
  
  
  return (
    <>
    {/* start hero section  */}
      <div className="hero it-hero">
         <img className='hero-img' src={hero} alt="" />
            <div className="hero-content-container">
                <div className="hero-content">
                <span>TechTorch / IT Consultancy</span><hr/>
                <h1 className='hero-heading'>Your goals, our expertise</h1>
                </div>
            </div>
      </div>
    {/* end hero section  */}
    
    {/* start consultancy section  */}
    <div className="container py-lg-5 py-3">
      <div className="row text-center pb-lg-5 pb-3">
        <h6>Consulting</h6>
        <h1>From strategy to impact.</h1>
        <p className='px-lg-5 px-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus, ex qui expedita, sapiente veniam esse aliquam voluptatibus perspiciatis quia quibusdam aliquid ea provident impedit magni! Perspiciatis, obcaecati nisi iusto cumque nostrum eos eum repellat accusantium laudantium odit quidem error veniam sed, voluptatum illum odio recusandae? Ut nesciunt odit quas quae?</p>
      </div>
      <div className="row">
        <div className="col-12">
        <h3>Explore Our Featured Insight</h3>
        <div className="it-image-container">
        <img className='img-fluid' src={it} alt="" />
        <div className="it-img-content-container">
            <div className="it-img-content">
            <h3>Why futurists are hopeful about AI transforming the future of work.</h3>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus, nisi? Laudantium animi repellendus est iusto aliquam.</p>
            <span>Learn More</span>
            </div>
        </div>
        </div>
        </div>
      </div>
    </div>
    {/* end consultancy section  */}

    {/* Start Our approach  */}
    <div className="our-approch-container py-lg-5 py-3">
        <div className="container">
          <div className="row">
            <h5 className='our-approach-heading'>Our Approach</h5>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="our-approach-content">
            <i class="bi bi-cloud"></i>
            <p>Enable business vision</p>
            </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="our-approach-content">
            <i class="bi bi-box"></i>
            <p>Empower data-led decisions</p>
            </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="our-approach-content">
            <i class="bi bi-clouds"></i>
            <p>Build resilience</p>
            </div>
            </div>
            
            <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="our-approach-content">
            <i class="bi bi-person"></i>
            <p>Accelerate innovation</p>
            </div>
            </div>
          </div>
        </div>
    </div>
    {/* End Our approach  */}

     {/* Start Benefits section  */}
     <div className="container py-lg-5 py-3">
      <div className="row">
       <div className="col-12">
       <h5 className='mb-lg-4 mb-3'>Benefits</h5>
        <h1 className='mb-lg-5 mb-3'>Turn your vision into reality. Unlock the full potential of your business.</h1>
        <ul className='benefits-list'>
          <li>Realize your purpose with a holistic transformation.</li>
          <li>Implement an enterprise-wide transformation, from vision and strategy to execution and outcomes.</li>
          <li>Ideate, create, and deploy new business models.</li>
          <li>Elevate customer and employee experiences.</li>
          <li>Realize your purpose with a holistic transformation.</li>
          <li>Innovate for continuous value creation.</li>
        </ul>
       </div>
      </div>
     </div>
     {/* End Benefits section  */}
    </>
  );
};

export default ITConsultancy;
