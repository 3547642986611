import React, { useState, useEffect, useRef} from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { FaSearch } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from 'react-router-dom';
import logo from '../../assets/images/Logo.png';
import './Navbar.css';

const NavigationBar = () => {
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [scrolled, setScrolled] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
  const submenuRef = useRef(null);
  

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSearchClick = () => {
    setSearchVisible(!searchVisible);
  };

  const openSubMenu = (id) => {
    setActiveSubMenu(id);
   
  };

  const closeSubMenu = () => {
    setActiveSubMenu(null);
   
  };

  const handleNavClick = () => {
    setIsNavbarCollapsed(true);
    
  };



  return (
    <>
      <Navbar
        expand="lg"
        fixed="top"
        className={`custom-navbar ${scrolled ? 'scrolled' : ''}`}
        expanded={!isNavbarCollapsed}
      >
        <div className='container'>

          <Navbar.Brand as={Link} to="/" onClick={handleNavClick}>
            <img src={logo} alt="Logo" className="navbar-logo" />
          </Navbar.Brand>     

         <i class="bi bi-list navbar-toggler" onClick={()=>setIsNavbarCollapsed(!isNavbarCollapsed)}></i>
         
          
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto menu-link-container">
              {['capabilities', 'discover', 'industries', 'insights'].map((item) => (
                <Nav.Link
                  key={item}
                  as={Link}
                  to={`/${item}`}
                  className='nav-link'
                  onMouseEnter={() => openSubMenu(item)}
                  onClick={handleNavClick}
                >
                  {item.charAt(0).toUpperCase() + item.slice(1)}
                  <IoIosArrowDown className={`dropdown-arrow ${activeSubMenu === item ? 'open' : ''}`} />
                </Nav.Link>
              ))}
              <Nav.Link
                as={Link}
                to="/clients"
                className='nav-link'
                onMouseLeave={closeSubMenu}
                onClick={handleNavClick}
              >
                Clients
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/contact"
                className='nav-link'
                onMouseLeave={closeSubMenu}
                onClick={handleNavClick}
              >
                Contact Us
              </Nav.Link>
              <Nav.Link onClick={handleSearchClick} className="d-lg-none nav-link">
                <FaSearch /> Search
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Button
            variant="outline-secondary"
            onClick={handleSearchClick}
            className="search-icon-btn"
          >
            <FaSearch />
          </Button>
        </div>

      </Navbar>

      <div className={`search-bar-container ${searchVisible ? 'visible' : ''}`}>
        <Container>
          <input
            type="text"
            placeholder="Search"
            className="search-input"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Container>
      </div>

      {activeSubMenu && (
        <div
          className="submenu-overlay"
          ref={submenuRef}
          onMouseLeave={closeSubMenu} // Close submenu when mouse leaves the overlay
        >

          {/*start Sub menu  */}
          <div
            className="submenu-content"
            onMouseEnter={() => openSubMenu(activeSubMenu)} // Keep submenu open while hovering
            onMouseLeave={closeSubMenu} // Close submenu when mouse leaves submenu content
          >
            {activeSubMenu === 'capabilities' && (
              <div className='capabilities-container'>
               
               <div>
                  <h4>Our Services</h4>
                  <ul>
                    <li><Link to="/it-consultancy" onClick={closeSubMenu}>IT Consultancy</Link></li>
                    <li><Link to="/artificial-intelligence" onClick={closeSubMenu}>Artificial Intelligence (AIaas)</Link></li>
                    <li><Link to="/cloud-infrastructure" onClick={closeSubMenu}>Cloud Infrastructure Services (IaaS)</Link></li>
                    <li><Link to="/cyber-security" onClick={closeSubMenu}>Cyber Security</Link></li>
                    <li><Link to="/software-engineering-services" onClick={closeSubMenu}>Software Engineering Services</Link></li>
                    <li><Link to="/business-process-services" onClick={closeSubMenu}>Business Process Services </Link></li>
                    <li><Link to="/software-developer" onClick={closeSubMenu}>Software Devel opment & Support </Link></li>
                    <li><Link to="/resource-staffing" onClick={closeSubMenu}>Resource and Staffing</Link></li>
                  </ul>
              </div>
                
                <div className='sub-menu-digital-container'>
                  <h4>Digital Solutions(Saas)</h4>
                  <ul>
                    <li><Link to="/erp" onClick={closeSubMenu}>ERP</Link></li>
                    <li><Link to="/operations-management" onClick={closeSubMenu}>Operations Management</Link></li>
                    <li><Link to="/supply-chain" onClick={closeSubMenu}>Supply Chain Management</Link></li>
                    <li><Link to="/aviation-management" onClick={closeSubMenu}>Aviation Management</Link></li>
                    <li><Link to="/people-resources" onClick={closeSubMenu}>People Resources</Link></li>
                    <li><Link to="/web-portals" onClick={closeSubMenu}>Web Portals</Link></li>
                    <li><Link to="/financial-management" onClick={closeSubMenu}>Financial Management</Link></li>
                    <li><Link to="/payment-management" onClick={closeSubMenu}>Payment Management</Link></li>
                    <li><Link to="/healthcare-management" onClick={closeSubMenu}>HealthCare & Hospital Management</Link></li>
                    <li><Link to="/crm" onClick={closeSubMenu}>CRM</Link></li>
                    <li><Link to="/ecommerce" onClick={closeSubMenu}>Ecommerce</Link></li>
                    <li><Link to="/project-management" onClick={closeSubMenu}>Project Management</Link></li>
                  </ul>
                </div>
              <div>
                 <h4>Products and Platforms</h4>
                  <ul>
                    <li><Link to="/corporation" onClick={closeSubMenu}>Corporation24x7</Link></li>
                    <li><Link to="/eduficera" onClick={closeSubMenu}>Eduficera</Link></li>
                  </ul>
              </div>
                 
                
              </div>
            )}
            {activeSubMenu === 'discover' && (
              <div>
                <h4>Discover TechTorch</h4>
                <ul>
                  <li><Link to="/idea" onClick={closeSubMenu}>Idea</Link></li>
                  <li><Link to="/leadership" onClick={closeSubMenu}>Leadership</Link></li>
                  <li><Link to="/investors" onClick={closeSubMenu}>Investors</Link></li>
                  <li><Link to="/partners-ecosystem" onClick={closeSubMenu}>Partners Ecosystem</Link></li>
                </ul> 
              </div>
            )}
            {activeSubMenu === 'industries' && (
              <div>
                <h4>Industries</h4>
                <ul>
                  <li><Link to="/finance" onClick={closeSubMenu}>Finance</Link></li>
                  <li><Link to="/education" onClick={closeSubMenu}>Education</Link></li>
                  <li><Link to="/medical" onClick={closeSubMenu}>Medical</Link></li>
                  <li><Link to="/energy" onClick={closeSubMenu}>Energy</Link></li>
                  <li><Link to="/transportation" onClick={closeSubMenu}>Transportation</Link></li>
                  <li><Link to="/fmcg" onClick={closeSubMenu}>FMCG</Link></li>
                </ul>
              </div>
            )}
            {activeSubMenu === 'insights' && (
              <div>
                <h4>Insights</h4>
                <ul>
                  <li><Link to="/news" onClick={closeSubMenu}>News</Link></li>
                  <li><Link to="/events" onClick={closeSubMenu}>Events</Link></li>
                  <li><Link to="/case-studies" onClick={closeSubMenu}>Case Studies</Link></li>
                  <li><Link to="/views" onClick={closeSubMenu}>Views</Link></li>
                  <li><Link to="/analyst" onClick={closeSubMenu}>Analyst</Link></li>
                </ul>
              </div>
            )}
          </div>
            {/*end Sub menu  */}
        </div>
      )}
    </>
  );
};

export default NavigationBar;
