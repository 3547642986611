import React from 'react'
import './Software_Engineering_Services.css'

const Software_Engineering_Services = () => {
    return (
        <div>
            {/* Hero Section  */}
            <div className="software-hero-section">
                <div className='software-hero-content'>
                    <h1>Software Engineering <br/>Services</h1>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi praesentium dolore deleniti consequuntur quidem architecto qui perferendis adipisci minima quaerat.</p>
                    <button className='main-btn'>TechTorch</button>
                </div>
            </div>
            {/* End Hero Section  */}

            <div className="container py-5">
                <div className="row">
                    <h1>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nihil, mollitia.</h1>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus, ad reprehenderit ipsam numquam possimus alias aperiam, recusandae voluptatibus suscipit, itaque exercitationem! Enim reiciendis est odit sunt, iure libero voluptatem! Accusantium porro laudantium nemo earum illo unde quia, quaerat corrupti voluptas saepe. Delectus est necessitatibus repudiandae. Quidem obcaecati ipsum hic asperiores.</p>
                </div>
                <div className="row second-image-container">
                    <img className='img-fluid' src="https://images.unsplash.com/photo-1713358645713-af1fe74857f8?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDV8fHxlbnwwfHx8fHw%3D" alt="" />
                </div>
            </div>

        </div>
    )
}

export default Software_Engineering_Services
