import React from 'react'
import './Cyber_Security.css'
import Button from '../../components/Button/Button'
import { Link } from 'react-router-dom'
import cyber1 from '../../assets/images/Cyber/cyber1.png'
import cyber2 from '../../assets/images/Cyber/cyber2.png'
import cyber3 from '../../assets/images/Cyber/cyber3.png'
import cyber4 from '../../assets/images/Cyber/cyber4.png'
import cyber5 from '../../assets/images/Cyber/cyber5.png'

const Cyber_Security = () => {
  return (
    <div>
      {/* Hero Section  */}
      <div className="cyber-hero-image py-5">
        <div className="cyber-hero-content fadeIn">
          <h1>Cyber Security </h1>
          <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque, aut.</h3>
          <p >Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium placeat, illo autem explicabo amet dolorem suscipit corrupti mollitia sequi? Explicabo?</p>
          <Button text="TechTorch"/>
        </div>
      </div>
    {/* End Hero Section  */}


    {/* Second Section  */}
    <div className='cyber-second-section'>
      <div className="container">
        <div className="row">
          <h2 className='text-center mb-4'>Lorem ipsum dolor sit, amet consectetur </h2>
          <div className="col-lg-6 col-md-6">
            <h4>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate, nam!</h4>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eius praesentium tempore officiis corrupti ipsum, quasi quos consequuntur, sit aliquid ut sunt blanditiis nemo dolore in delectus doloremque voluptas mollitia doloribus sint, similique deleniti architecto laboriosam! Odio ipsum tempora, sequi quae facilis sit, cupiditate dignissimos ex beatae atque, enim ratione quaerat at. Quod, reiciendis obcaecati. Iusto, blanditiis repellendus? Voluptas, reiciendis voluptates?</p>
          </div>
          <div className="col-lg-6 col-md-6">
          <h4>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate, nam!</h4>
          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eius praesentium tempore officiis corrupti ipsum, quasi quos consequuntur, sit aliquid ut sunt blanditiis nemo dolore in delectus doloremque voluptas mollitia doloribus sint, similique deleniti architecto laboriosam! Odio ipsum tempora, sequi quae facilis sit, cupiditate dignissimos ex beatae atque, enim ratione quaerat at. Quod, reiciendis obcaecati. Iusto, blanditiis repellendus? Voluptas, reiciendis voluptates?</p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-3 col-md-4 col-sm-6 text-center">
          <i class="fa-solid fa-cloud"></i>
            <h3>Lorem ipsum dolor sit amet</h3>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempora amet dolor architecto tempore ducimus vitae accusamus, perferendis aliquid minima non.</p>
            <Link to="">See more</Link>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 text-center">
          <i class="fa-solid fa-globe"></i>
          <h3>Lorem ipsum dolor sit amet</h3>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempora amet dolor architecto tempore ducimus vitae accusamus, perferendis aliquid minima non.</p>
            <Link to="">See more</Link>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 text-center">
          <i class="fa-solid fa-user"></i>
          <h3>Lorem ipsum dolor sit amet</h3>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempora amet dolor architecto tempore ducimus vitae accusamus, perferendis aliquid minima non.</p>
            <Link to="">See more</Link>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 text-center">
          <i class="fa-solid fa-lock"></i>
          <h3>Lorem ipsum dolor sit amet</h3>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempora amet dolor architecto tempore ducimus vitae accusamus, perferendis aliquid minima non.</p>
            <Link to="">See more</Link>
          </div>
        </div>
      </div>
    </div>
    {/* End Second Section  */}

    {/* Third Section  */}
    <div className="container py-lg-5">
      <div className="row">
        <div className="col-lg-7 col-md-7 col-sm-6">
            <h2>This is Heading</h2>
            <p className='lh-lg'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquam ipsa quos amet doloremque reprehenderit fuga quasi accusantium obcaecati dolores, delectus ullam, aut cum dolore accusamus temporibus neque itaque quia similique deserunt repellendus natus numquam sunt voluptates! Minus illo aut eos natus corporis quia officia atque! Officiis voluptate saepe corrupti, laborum in aut aspernatur amet necessitatibus fugiat ipsa cum, sequi quia! Quo praesentium esse unde sunt aut quidem eveniet quasi ipsa molestias aspernatur nam recusandae maxime odio officia nobis inventore pariatur natus eos, assumenda nemo cum, tenetur optio ullam neque! Praesentium omnis dolor hic quos nisi sint culpa aperiam velit quisquam!</p>
        </div>
        <div className="col-lg-5 col-md-5 col-sm-6">
            <img className='img-fluid' src={cyber1} alt="" />
        </div>
      </div>
      <div className="row mt-5">
      <div className="col-lg-5 col-md-5 col-sm-6">
            <img className='img-fluid' src={cyber2} alt="" />
        </div>
        <div className="col-lg-7 col-md-7 col-sm-6">
            <h2>This is Heading</h2>
            <p className='lh-lg'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquam ipsa quos amet doloremque reprehenderit fuga quasi accusantium obcaecati dolores, delectus ullam, aut cum dolore accusamus temporibus neque itaque quia similique deserunt repellendus natus numquam sunt voluptates! Minus illo aut eos natus corporis quia officia atque! Officiis voluptate saepe corrupti, laborum in aut aspernatur amet necessitatibus fugiat ipsa cum, sequi quia! Quo praesentium esse unde sunt aut quidem eveniet quasi ipsa molestias aspernatur nam recusandae maxime odio officia nobis inventore pariatur natus eos, assumenda nemo cum, tenetur optio ullam neque! Praesentium omnis dolor hic quos nisi sint culpa aperiam velit quisquam!</p>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-lg-7 col-md-7 col-sm-6">
            <h2>This is Heading</h2>
            <p className='lh-lg'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquam ipsa quos amet doloremque reprehenderit fuga quasi accusantium obcaecati dolores, delectus ullam, aut cum dolore accusamus temporibus neque itaque quia similique deserunt repellendus natus numquam sunt voluptates! Minus illo aut eos natus corporis quia officia atque! Officiis voluptate saepe corrupti, laborum in aut aspernatur amet necessitatibus fugiat ipsa cum, sequi quia! Quo praesentium esse unde sunt aut quidem eveniet quasi ipsa molestias aspernatur nam recusandae maxime odio officia nobis inventore pariatur natus eos, assumenda nemo cum, tenetur optio ullam neque! Praesentium omnis dolor hic quos nisi sint culpa aperiam velit quisquam!</p>
        </div>
        <div className="col-lg-5 col-md-5 col-sm-6">
            <img className='img-fluid' src={cyber3} alt="" />
        </div>
      </div>
      <div className="row mt-5">
      <div className="col-lg-5 col-md-5 col-sm-6">
            <img className='img-fluid' src={cyber4} alt="" />
        </div>
        <div className="col-lg-7 col-md-7 col-sm-6">
            <h2>This is Heading</h2>
            <p className='lh-lg'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquam ipsa quos amet doloremque reprehenderit fuga quasi accusantium obcaecati dolores, delectus ullam, aut cum dolore accusamus temporibus neque itaque quia similique deserunt repellendus natus numquam sunt voluptates! Minus illo aut eos natus corporis quia officia atque! Officiis voluptate saepe corrupti, laborum in aut aspernatur amet necessitatibus fugiat ipsa cum, sequi quia! Quo praesentium esse unde sunt aut quidem eveniet quasi ipsa molestias aspernatur nam recusandae maxime odio officia nobis inventore pariatur natus eos, assumenda nemo cum, tenetur optio ullam neque! Praesentium omnis dolor hic quos nisi sint culpa aperiam velit quisquam!</p>
        </div>
        
      </div>
      <div className="row mt-5">
        <div className="col-lg-7 col-md-7 col-sm-6">
            <h2>This is Heading</h2>
            <p className='lh-lg'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquam ipsa quos amet doloremque reprehenderit fuga quasi accusantium obcaecati dolores, delectus ullam, aut cum dolore accusamus temporibus neque itaque quia similique deserunt repellendus natus numquam sunt voluptates! Minus illo aut eos natus corporis quia officia atque! Officiis voluptate saepe corrupti, laborum in aut aspernatur amet necessitatibus fugiat ipsa cum, sequi quia! Quo praesentium esse unde sunt aut quidem eveniet quasi ipsa molestias aspernatur nam recusandae maxime odio officia nobis inventore pariatur natus eos, assumenda nemo cum, tenetur optio ullam neque! Praesentium omnis dolor hic quos nisi sint culpa aperiam velit quisquam!</p>
        </div>
        <div className="col-lg-5 col-md-5 col-sm-6">
            <img className='img-fluid' src={cyber5} alt="" />
        </div>
      </div>
      
    </div>
    {/* End Third Section  */}

    {/* Fourth Section  */}
    <div className='cyber-card-section'>
        <div className="container">
          <div className="row">
          </div>
        </div>
    </div>
    {/* End Fourth Section  */}


    </div>
  )
}

export default Cyber_Security
