import React from 'react';
import { Link } from 'react-router-dom'
import './Card.css';

const Card = ({ image, text, heading,link}) => {
  return (
    <div className="card main-card mb-4">
      <div className='image-container'>
        <img className='img-fluid card-img' src={image} alt="..." />
        <h5 className='card-heading'>{heading}</h5> {/* Use the heading prop */}
      </div>
      <div className='card-content'>
        <div className='content'>
          <p>{text}</p>
          <Link to={`/${link}`} className='link'>Expand</Link>
        </div>
      </div>
    </div>
  );
}

export default Card;
