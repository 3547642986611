import React from 'react'
import './Cloud_Infrastructure.css';
import Card from './Cloud Card/Card';

const Cloud_Infrastructure = () => {
    return (
        <div>

            {/* hero section  */}
            <div className="cloud-hero-image">
                <div className="cloud-hero-content">
                    <h1 className='cloud-hero-heading'>Cloud Infrastructure Services</h1>
                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Enim debitis aliquid tenetur minima maxime, eligendi possimus neque quidem fuga! Aliquam.</p>
                </div>
            </div>
            {/* end hero section  */}

            {/* content section  */}
            <div className="container">
                <div className="row py-5">
                    <div className="col-lg-6">
                        <h1>What are Cloud Services?</h1>
                        <p className='mt-3 lh-lg'>Cloud services are application and infrastructure resources that exist on the Internet. Third-party providers contract with subscribers for these services, allowing customers to leverage powerful computing resources without having to purchase or maintain hardware and software.</p>
                    </div>
                </div>
            </div>
           {/* end content section  */}

         {/* Second Image Section  */}
            <div className='cloud-second-image'>
                <div className='cloud-second-image-content'>
                    <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus iusto reprehenderit fuga, culpa at iure laudantium placeat dolore quod nostrum.
                    </h3>
                </div>
            </div>
        {/* end Second Image Section  */}

        {/* Card Section  */}
        <div className="container cloud-main-card-container">
            <div className="row cloud-card-container ">
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <Card
                        percentage="55%"
                        heading="This is heading"
                        content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper."
                    />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <Card
                        percentage="43%"
                        heading="This is heading"
                        content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper."
                    />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <Card
                        percentage="63%"
                        heading="This is heading"
                        content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper."
                    />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <Card
                        percentage="52%"
                        heading="This is heading"
                        content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper."
                    />
                </div>
            </div>
        </div>
        {/* End Card Section  */}



        </div>
    )
}

export default Cloud_Infrastructure
